import React, { useEffect, useState } from 'react';

const Home = () => {
  const [mousePos, setMousePos] = useState({});
  const [isFlat, setIsFlat] = useState(true);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: (window.innerWidth/2 - event.clientX)/10, y: (window.innerHeight/2 - event.clientY)/5, delta: event.clientY > window.innerHeight/2 ? -1 : 1 });
      console.log(event.clientY > window.innerHeight/2 ? 1 : -1)
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsFlat(false)
    }, 1000)
  }, [])

  return (
    <div className='home-wrapper'>
      <div className='text-wrapper' data-isflat={isFlat}>
        <p className='black'>We build for&nbsp;and&nbsp;with creative disruptors.</p>
        <p className='g1' style={{ transform: 'translate3d('+mousePos.x*10/10+'px, '+(mousePos.y*10/10)+'px, 0)' }}>We build for&nbsp;and&nbsp;with creative disruptors.</p>
        <p className='g2' style={{ transform: 'translate3d('+mousePos.x*15/10+'px, '+(mousePos.y*15/10)+'px, 0)' }}>We build for&nbsp;and&nbsp;with creative disruptors.</p>
        <p className='g3' style={{ transform: 'translate3d('+mousePos.x*20/10+'px, '+(mousePos.y*20/10)+'px, 0)' }}>We build for&nbsp;and&nbsp;with creative disruptors.</p>
        <p className='g4' style={{ transform: 'translate3d('+mousePos.x*25/10+'px, '+(mousePos.y*25/10)+'px, 0)' }}>We build for&nbsp;and&nbsp;with creative disruptors.</p>
        <p className='g5' style={{ transform: 'translate3d('+mousePos.x*30/10+'px, '+(mousePos.y*30/10)+'px, 0)' }}>We build for&nbsp;and&nbsp;with creative disruptors.</p>
      </div>

      <div class="bottom-text">
        <span>We are CP Studio.</span>
        <a href="mailto:rob@respect.art">Let's talk.</a>
      </div>
    </div>
  )
};

export default Home;
