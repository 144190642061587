import React from 'react';
import Home from 'components/Home';

const HomePage = () => {
    return (
      <Home></Home>
  );
};

export default HomePage;
