import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import HomePage from './screens/HomePage';

function App() {
  return (
    <BrowserRouter>
      <div className={'app-wrapper'}>
        <main>
          <Suspense fallback={<>Loading ...</>}>
            <Routes>
              <Route exact path={'/'} element={<HomePage />} />
              <Route path={'*'} element={<p>404</p>} />
            </Routes>
          </Suspense>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
